// getPaginationData.js
'use strict';
import { Collection, Map } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

const defaultMap = new Map();

/**
 * Select pagination data by data key
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {array} selectPath - select path.
 * @param {string} dataKey - select data key.
 * @param {array} filterIds - for filter.
 * @param {number} limit - the items displayed on the page.
 * @param {number} page - the page the user is querying.
 * @return {any} The selected list data.
 */
const getPaginationData = createCachedSelector(
  (state, selectPath) => state.getIn(['paginations', ...selectPath]),
  (state, selectPath, dataKey) => dataKey,
  // Currently we don't use `filterIds` in getPaginationData.
  // We reserve this parameter to align `getListData` (may make it easier for further migration)
  (state, selectPath, dataKey, filterIds) => filterIds,
  (state, selectPath, dataKey, filterIds, limit) => limit,
  (state, selectPath, dataKey, filterIds, limit, page) => page,
  (list = defaultMap, dataKey, filterIds, limit, page) => {
    let result = undefined;
    if (list) {
      result = list.get(dataKey);
      if ('itemIds' === dataKey) {
        const itemIdArray = [];
        const itemIdMap = list.get('itemIds');
        const totalCount = list.get('totalCount');
        if (itemIdMap) {
          for (let i = 0; i < limit; i++) {
            const itemIndex = limit * (page - 1) + i;
            if (itemIndex < totalCount && itemIdMap.get(`${itemIndex}`)) {
              itemIdArray[i] = itemIdMap.get(`${itemIndex}`);
            }
          }
        }
        result = itemIdArray;
      }
    }
    return result instanceof Collection ? result.toJS() : result;
  }
)((state, selectPath, dataKey) => `${selectPath.join(':')}:${dataKey}`);

export default getPaginationData;
