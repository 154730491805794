// DiscoverFlixProvider.js
import { connect } from 'react-redux';

import { MERGE_OPERATION_DATA } from '../ActionTypes.js';

import fetchFeeds from '../action/fetchFeeds.js';

import getListData from '../selector/getListData.js';
import getPaginationData from '../selector/getPaginationData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getMeData from '../selector/getMeData.js';
import getOperationData from '../selector/getOperationData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';

import { FeedNameType } from '../resource/feedConstants.js';
import {
  PAGINATION_ITEM_COUNT_PER_PAGE,
  PAGINATION_ITEM_COUNT_PER_ROW,
} from '../RemoteConfigKeys.js';

const mapStateToProps = (state, { category, location, isInPaginationMode }) => {
  const { search } = location;
  const searchParmas = new URLSearchParams(search);
  const currentPage = Number(searchParmas.get('page')) || 1;
  const selectPath = ['home', FeedNameType.MESSAGES, category];
  const nextPage = getListData(state, selectPath, 'nextPage');
  const totalCount = isInPaginationMode
    ? getPaginationData(state, selectPath, 'totalCount')
    : getListData(state, selectPath, 'totalCount');
  const itemCountPerRow = getRemoteConfigData(
    state,
    PAGINATION_ITEM_COUNT_PER_ROW
  );
  const itemCountPerPage = getRemoteConfigData(
    state,
    PAGINATION_ITEM_COUNT_PER_PAGE
  );
  const limit = itemCountPerPage;
  return {
    // use token instead of userId to reduce list api call
    isAuthed: !!getMeData(state, 'token'),
    messageIds: isInPaginationMode
      ? getPaginationData(
          state,
          selectPath,
          'itemIds',
          undefined,
          limit,
          currentPage
        )
      : getListData(state, selectPath, 'itemIds'),
    nextPage,
    totalCount,
    limit,
    currentPage,
    itemCountPerRow,
    itemCountPerPage,
    isCurrentPageFetched: !!getNetworkingData(
      state,
      [...selectPath, currentPage, limit],
      'isFetched'
    ),
    isFetched: getNetworkingData(state, [...selectPath, 1], 'isFetched'),
    isFetching: getNetworkingData(
      state,
      [...selectPath, nextPage],
      'isFetching'
    ),
    shouldPlayFeedId: getOperationData(state, ['home'], 'shouldPlayFeedId'),
    scrollLeft: getOperationData(
      state,
      ['home', 'flix', category],
      'scrollLeft'
    ),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFeeds: data => dispatch(fetchFeeds(data)),
    setScrollLeft: ({ scrollLeft, category }) =>
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['home', 'flix', category],
          data: {
            scrollLeft,
          },
        },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
